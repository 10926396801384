
import {
  defineAsyncComponent,
  defineComponent,
  PropType,
  SetupContext,
} from 'vue';

/** Interfaces */
import { AgreementsI } from '@/interfaces/general.interface';

/** Composables */
import { usePaymentCard } from '@/composition/usePaymentCard';
import { useUtilities } from '@/composition/useUtilities';
import { ObligationTypes } from '@/utils/multi-pdf-types';

export default defineComponent({
  name: 'AgreementCard',
  components: {
    Tooltips: defineAsyncComponent(
      () => import('@/components/shared/Tooltips.vue')
    ),
    Loader: defineAsyncComponent(
      () => import('@/components/loader/Loader.vue')
    ),
  },
  props: {
    agreements: {
      type: Array as PropType<AgreementsI[]>,
      required: true,
    },
    isLoadingPaymentStatus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['view-detail-plan', 'make-payment'],
  setup(_props, context: SetupContext) {
    const obligationType = ObligationTypes.AGREEMENT;
    const {
      bindClassState,
      hiddenPartialData,
      viewDetailPlan,
      makePayment,
      hasBussiness,
      valueGreaterThanZero,
      getReference,
      canMakePayment,
      getLastPaymentStatus,
    } = usePaymentCard(context);

    const { validatePaymentDate } = useUtilities();

    return {
      bindClassState,
      hiddenPartialData,
      viewDetailPlan,
      makePayment,
      hasBussiness,
      validatePaymentDate,
      obligationType,
      valueGreaterThanZero,
      getReference,
      canMakePayment,
      getLastPaymentStatus,
    };
  },
});

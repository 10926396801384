/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/** Utilidades */
import { formatDate } from '@/utils/general-utils';

export function useUtilities() {
  const validatePaymentDate = (
    date: string,
    state: string | undefined
  ): string => {
    try {
      const immediate = 'Inmediato';
      const delinquentDebt = state?.toLowerCase() === 'en mora';

      if (date === 'N/A') {
        return date;
      }

      if (delinquentDebt || date === immediate) {
        return immediate;
      }

      const today = new Date();

      const dateFragment = date.split('-');

      const paymentDate = new Date(
        parseInt(dateFragment[0]),
        parseInt(dateFragment[1]) - 1,
        parseInt(dateFragment[2]),
        today.getHours(),
        today.getMinutes(),
        today.getSeconds(),
        today.getMilliseconds()
      );

      if (today.getTime() > paymentDate.getTime()) {
        return immediate;
      }

      return formatDate(paymentDate);
    } catch (error) {
      return 'No disponible';
    }
  };

  const setHomologateProductsTitle = (title: string) => {
    const HOMOLOGATE_TITLES: Record<string, string> = {
      'Al instante': 'Pago a 1 Cuota',
      '1, 2, 3': 'Trato 3 meses',
      'Cero-Seis': 'Trato 6 meses',
      'Cero-Doce': 'Trato 12 meses',
      'A plazos': 'Trato 2 a 3 años',
      'Dacion en Pago': 'Dacion en Pago',
      Remate: 'Remate',
      'Reestructuracion al instante': 'Reestructuracion al instante',
      'Reestructuracion 1, 2, 3': 'Reestructuracion 1, 2, 3',
      'Reestructuracion a plazos': 'Reestructuracion a plazos',
      'Cesion de Derechos de Credito': 'Cesion de Derechos de Credito',
      'Bajo Cero': 'Bajo Cero',
      'DE UNA': 'DE UNA',
      'Venta de Activos': 'Venta de Activos',
      '50 o +': 'Trato Mitad y Mitad',
      'Trato Gradual 7 meses': 'Trato a 7 meses',
      'Trato Gradual 13 meses': 'Trato a 13 meses',
    };

    return HOMOLOGATE_TITLES[title] || title;
  };

  return {
    validatePaymentDate,
    setHomologateProductsTitle,
  };
}
